"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubscriptionDetails = exports.navigateToPaymentSession = exports.navigateToCustomerPortal = void 0;
const Queries_1 = require("./Queries");
const navigateToCustomerPortal = (user) => async () => {
    if (user) {
        window.location.href = await (0, Queries_1.getStripeCustomerSessionURL)(user);
    }
};
exports.navigateToCustomerPortal = navigateToCustomerPortal;
const navigateToPaymentSession = (subItem) => async () => {
    window.location.href = await (0, Queries_1.getStripePaymentSessionURL)(subItem);
};
exports.navigateToPaymentSession = navigateToPaymentSession;
exports.getSubscriptionDetails = Queries_1.getStripeSubscriptions;
