"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserCount = exports.getUserDetails = void 0;
const log_1 = require("../utils/log");
const Queries_1 = require("./Queries");
const getUserDetails = (userEmail) => async () => {
    if (!userEmail)
        return undefined;
    const user = await (0, Queries_1.getUserFromAPI)(userEmail);
    if (!user) {
        (0, log_1.logError)("[User.ts] (getUserDetails) No user details found");
        return undefined;
    }
    return user;
};
exports.getUserDetails = getUserDetails;
const updateUserCount = (newCount) => (old) => {
    if (!old)
        return undefined;
    return {
        ...old,
        CurrCount: newCount,
    };
};
exports.updateUserCount = updateUserCount;
