"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSQLQuery = void 0;
const react_query_1 = require("@tanstack/react-query");
function useSQLQuery(queryKeys, queryFn, enabled = true) {
    return (0, react_query_1.useQuery)({
        queryKey: queryKeys,
        enabled,
        queryFn,
    });
}
exports.useSQLQuery = useSQLQuery;
