"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSQLMutation = void 0;
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
function useSQLMutation(mutationKeys, mutationFn, queryKey, invalidateQuery, updateFn, errRollbackDelay, errRollbackFn) {
    const queryClient = (0, react_query_1.useQueryClient)();
    const { mutate, isError } = (0, react_query_1.useMutation)(mutationKeys, mutationFn, {
        onSettled: async (newData, error, variables, context) => {
            if (queryKey) {
                await queryClient.cancelQueries({ queryKey });
                const prevData = queryClient.getQueryData(queryKey);
                if (error) {
                    queryClient.setQueryData(queryKey, prevData);
                }
                else {
                    if (updateFn)
                        queryClient.setQueryData(queryKey, updateFn);
                }
                if (invalidateQuery) {
                    await queryClient.invalidateQueries({ queryKey });
                }
            }
        },
    });
    const [foundError, setFoundError] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (queryKey && isError) {
            const timer = window.setTimeout(() => {
                setFoundError(false);
                if (errRollbackFn)
                    errRollbackFn();
            }, errRollbackDelay);
            setFoundError(true);
            return () => {
                window.clearTimeout(timer);
            };
        }
    }, [isError]);
    return { mutate: mutate, error: foundError };
}
exports.useSQLMutation = useSQLMutation;
