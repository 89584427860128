"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAPIResponse = void 0;
const axios_1 = __importStar(require("axios"));
const instance = axios_1.default.create({
    baseURL: process.env.BASE_API_URL,
    timeout: 30000,
    responseType: "json",
});
function printAxiosError(err) {
    throw new Error("AxiosError\n" +
        "StatusCode  " +
        err.code +
        "\n" +
        "Status      " +
        err.response.status +
        "\n" +
        "Error Msg   " +
        err.response.data.error);
}
async function getAPIResponse(method, url, params = {}, headers = axios_1.AxiosHeaders.from({}), responseType = "json") {
    if (method == "GET") {
        return instance({
            url,
            method,
            responseType,
            params,
            headers,
        })
            .then((response) => {
            return Promise.resolve(response.data);
        })
            .catch(printAxiosError);
    }
    else {
        return instance({
            url,
            method,
            responseType,
            data: params,
            headers,
        })
            .then((response) => {
            return Promise.resolve(response.data);
        })
            .catch(printAxiosError);
    }
}
exports.getAPIResponse = getAPIResponse;
