"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryKey = exports.getStripeCustomerSessionURL = exports.getStripePaymentSessionURL = exports.getStripeSubscriptions = exports.saveNewCountError = exports.saveNewCount = exports.getUserFromAPI = void 0;
const api_1 = require("../utils/api");
const log_1 = require("../utils/log");
const getUserFromAPI = async (userEmail) => {
    (0, log_1.log)("Running API Query: 'getUserFromAPI'");
    const data = await (0, api_1.getAPIResponse)("GET", "/user", {
        UserEmail: userEmail,
    });
    if (!data) {
        return undefined;
    }
    return data.user;
};
exports.getUserFromAPI = getUserFromAPI;
const saveNewCount = (userID, newCount) => async () => {
    if (!userID)
        return 0;
    (0, log_1.log)("Running API Query: 'saveNewCount'");
    const newCountRes = await (0, api_1.getAPIResponse)("POST", "/save-count", {
        UserID: userID,
        NewCount: newCount,
    });
    return newCountRes;
};
exports.saveNewCount = saveNewCount;
const saveNewCountError = (userID, newCount) => async () => {
    if (!userID)
        return 0;
    (0, log_1.log)("Running API Query: 'saveNewCount'");
    const newCountRes = await (0, api_1.getAPIResponse)("POST", "/save-count-error", {
        UserID: userID,
        NewCount: newCount,
    });
    return newCountRes;
};
exports.saveNewCountError = saveNewCountError;
const getStripeSubscriptions = async () => {
    (0, log_1.log)("Running API Query: 'getStripeSubscriptions'");
    const product_data = await (0, api_1.getAPIResponse)("GET", "/stripe/get-product-details");
    return product_data.items;
};
exports.getStripeSubscriptions = getStripeSubscriptions;
const getStripePaymentSessionURL = async (subItem) => {
    (0, log_1.log)("Running API Query: 'getStripePaymentSessionURL'");
    const { sessionURL } = await (0, api_1.getAPIResponse)("POST", "/stripe/create-checkout-session", {
        subFrequency: subItem.product.price.frequency,
        subPrice: subItem.product.price.amount,
    });
    return sessionURL;
};
exports.getStripePaymentSessionURL = getStripePaymentSessionURL;
const getStripeCustomerSessionURL = async (user) => {
    (0, log_1.log)("Running API Query: 'getStripeCustomerSessionURL'");
    const { sessionURL } = await (0, api_1.getAPIResponse)("POST", "/stripe/create-portal-session", {
        UserID: user.UserID,
        StripeCustomerID: user.StripeCustomerID,
    });
    return sessionURL;
};
exports.getStripeCustomerSessionURL = getStripeCustomerSessionURL;
exports.QueryKey = {
    GET_USER_DETAILS: ["GET_USER_DETAILS"],
    SAVE_COUNT: ["SAVE_COUNT"],
    SAVE_COUNT_WITH_ERROR: ["SAVE_COUNT_WITH_ERROR"],
    GET_STRIPE_SUB_DETAILS: ["GET_SUB_DETAILS"],
    GET_STRIPE_PAY_URL: ["GET_STRIPE_PAY_URL"],
    GET_STRIPE_CUSTOMER_URL: ["GET_STRIPE_CUSTOMER_URL"],
};
